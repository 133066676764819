import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { APIHandler } from "../../utils/axiosInstance";

import ProductCarousel from "./ProductCarousel";
import ProductDetail from "./ProductDetail";
import Seo from "../../components/common/Seo";


const ProductDetails = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [product, setProduct] = useState([]);

  const SEO_DATA = [
    {
      id: 1,
      name: "VIR V1",
      title: "VIR Bike V1 Electric Cycle with 50km Range, Detachable battery, Aluminium Alloy Frame, Front Suspension, Multifunctional Digital Display, Horn+Torchlight with 250W Hub Motor",
      description: "Elevate your ride with the VIR Bike V1 Electric Cycle, engineered for exceptional on road and off-road performance with luxury. Featuring a lightweight 17\" Aluminium Alloy 6061 Frame and front suspension, this electric bike offers unparalleled durability and comfort for any terrain. Its sophisticated multifunctional digital display and removable battery with a 50km range ensure advanced functionality and convenience. VIR Bike V1 Electric bicycle is powered by a powerful 250W Hub Motor, the striking Rafael Grey and Tank Green finish combines style and strength for a truly premium cycling experience.",
    },
    {
      id: 2,
      name: "VIR V2",
      title: "VIR Bike V2 Unisex Electric Cycle Aluminium Alloy 6061, 29\" Wheel size with 10.2Ah Removable Battery, Coloured LCD Display, 5 Levels Pedal Assist, Shimano 7-speed Gear, Front Light, Lock-Unlock Front Suspension, 250W BLDC Motor",
      description: "Introducing the VIR Bike V2 Unisex Electric Cycle, engineered for exceptional performance and comfort. Built with a sturdy Aluminium Alloy 6061 frame, this electric bike features a powerful 10.2Ah removable battery for extended on road and off road rides. The vibrant coloured LCD display keeps you informed with real-time data, while the 5 levels of pedal assist and Shimano 7-speed gears ensure smooth and efficient cycling. VIR Bike V2 Electric Bicycle is equipped with a front light for enhanced visibility and a lock-unlock front suspension for customizable comfort, the 250W BLDC motor guarantees a powerful and reliable ride. The stunning Ferra Red finish & McLa Orange adds a touch of style to this versatile and high-performance e-bike.",
    },
    {
      id: 3,
      name: "VIR V3",
      title: "VIR Bike V3 Desert Raptor 27.5\" Electric Bike with Maxxis Tyres, Detachable In-Frame Battery 70KM Range, Aluminium Alloy Frame, Horn + Torchlight, 5-Level Multifunctional Display, 250W Hub Motor",
      description: "Experience the power and elegance of the VIR Bike V3 Desert Raptor, a premium electric cycle built for ultimate performance and versatility. With a European-inspired design, this sleek e bike features a lightweight 17\" unisex aluminum alloy frame and MAXXIS tires for superior grip and stability across various terrains. The detachable battery is seamlessly housed in a tube, giving the VIR Bike V3 Desert Raptor Electric Bicycle a streamlined, aerodynamic look, while the powerful 250W hub motor ensures impressive speed and performance. The 5-level multifunctional display keeps you informed during your ride, making the Desert Raptor V3 the ideal choice for those seeking a stylish, high-performance electric bike that combines functionality with bold design.",
    }
  ]

  useEffect(() => {
    let productId = localStorage.getItem("navigatingTo");
    const fetchProductById = async () => {
      const res = await APIHandler(
        "GET",
        `/products/${productId}`,
        null,
        {
          "content-type": "application/json; charset=utf-8"
        },
      );

      if (res.status !== 200) {
        console.log("No data is present")
      } else {     
        setIsLoaded(true);
        setProduct(res.data);
      }
    }

    fetchProductById();
  }, [isLoaded]);


  // Dynamically set SEO data based on productName
  const getSeoData = (productName) => {
    const seoData = SEO_DATA.find(item => item.name === productName);
    return seoData || {}; // Return an empty object if no match is found
  };

  const seoData = product ? getSeoData(product.productName) : {};

  return (
    <>
      {product && (
        <Seo
          title={seoData.title || "VIR BIKE - Electric Cycle"}
          description={seoData.description || "Discover the best electric cycle for your needs with VIR BIKE."}
          name={`${product.productName} - ${product.tagline}`}
          type="webapp"
        />
      )}

      { isLoaded && product && (
        <section id="product-detail-section">
          <div className="container-fluid product-detail-container p-0">
            <div className="prod-details_left-col">
              <div className="prod-details-heading">
                {product.productName}
              </div>
              <div>
                <ProductCarousel images={product.productImages} component="details"/>
                <div className="details-and-image">
                  <div className="product-details">
                    <h2 className="product-details-heading mb-5">Details</h2>
                    {
                      product?.specs?.map(item => (
                        <>
                          <div 
                            className="product-details-info row"
                            key={item.name}>
                            <div className="col fw-bold">
                              <p>{item.name}</p>
                            </div>
                            <div className="col text-dark-gray">
                              <p>{item.value}</p>
                            </div>                      
                          </div>
                          <hr />
                        </>
                      ))
                    }  
                  </div>
                  <figure className="mt-0">
                    <img loading="lazy" src="/images/height.jpg" className="img-fluid w-100" alt="VIR V1" />
                  </figure>
                </div>
                {
                  product && product.bannerPrimary &&
                  <figure>
                    <img loading="lazy" src={product.bannerPrimary} className="img-fluid w-100" alt="VIR V1" />
                  </figure>
                }
                {
                  product && product.bannerSecondary &&
                  <figure>
                    <img loading="lazy" src={product.bannerSecondary} className="img-fluid w-100" alt="VIR V1" />
                  </figure>
                }
              </div>
              {/* <div className="prod-details_short-description">
                {
                  product?.specs?.map(item => (
                    <div 
                      className="short-description-info"
                      key={item.name}>
                      <p>{item.name}</p>
                      <h5>{item.value}</h5>
                    </div>
                  ))
                }            
              </div> */}
            </div>
            <div className="prod-details_right-col">
              <ProductDetail product={product}/>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ProductDetails;