import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { BOOK_NOW } from "../../constants.js";

const ProductCard = ({ product }) => {
  const [imageURL, setImageURL] = useState(product.productImages[0] || "");
  const [selectedColorIndex, setSelectedColorIndex] = useState(0); // Track selected button index
  const navigate = useNavigate();

  useEffect(() => {
    // Highlight the first button on load
    setImageURL(product.colorOptions[0]?.image[0] || "");
  }, [product]);

  const selectProductVariation = (e, type, index) => {
    e.preventDefault();
    setImageURL(type.image[0] || "");
    setSelectedColorIndex(index); // Update the selected button index
  };

  const bookNow = () => {
    navigate(`/product/${product.prettyUrl}`);
    localStorage.setItem("navigatingTo", product._id);
  };

  if (!product) return null; // Add a loading state or error handling as needed

  return (
    <div className="prod-card">
      <div className="prod-heading">
        <h2>{product.productName}</h2>
        <p className="text-light-gray">{product.tagline}</p>
      </div>
      <div className="prod-section-img">
        <figure>
          <img
            src={imageURL}
            className="prod-img img-fluid"
            alt={product.productName}
          />
        </figure>
      </div>
      <div className="prod-info">
        <div className="color-option d-flex justify-content-end">
          {product.colorOptions.map((type, index) => (
            <button
              style={{
                backgroundColor: type.colorCode,
                cursor: "pointer",
                position: "relative",
                margin: "0 8px", // Add spacing between buttons
                padding: "4px",
                border: selectedColorIndex === index ? "none" : "none",
                outline: selectedColorIndex === index ? "2px solid #556b2f" : "none",
                outlineOffset: "4px",
              }}
              className="round-btn"
              title={type.color}
              key={`${type.colorCode}-${index}`} // Ensure unique key
              onClick={(e) => selectProductVariation(e, type, index)}
              aria-label={`Select color ${type.color}`}
            ></button>
          ))}
        </div>
        <div className="d-flex flex-wrap justify-content-between align-items-center mt-4">
          <h4 className="mb-0 fw-semibold">{product.productName}</h4>
          <h4 className="mb-0 fw-semibold">
            ₹{product.pricing.originalPrice}{" "}
            <span className="fs-5 fw-normal">
              <s>₹{product.pricing.slashPrice}</s>
            </span>
          </h4>
        </div>
        <div className="prod-booking mt-4">
          <Button className="w-100 product-booking-btn" onClick={bookNow}>
            {BOOK_NOW} &#8594;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
